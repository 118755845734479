import { useEffect, useState } from "react";
import "../App.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { FETCH_BILLING_ADDRESS_URL, GET_EVENT_CONTRACT_FILE, USER_ELLIGIBLE, generalGetMethos, generalPostMethod, getCurrentUser, postPrepaymentData } from "./requests";
import CountrySelector from "./country-selector";
import CitiesJson from "./cities";
import DisplayBillingAddress from "./billing/display-billn-address";
import AddBillingAddress from "./billing/add-bill-address";
import EditBillnAddress from "./billing/edit-billn-address";
import { formatToNormal, formatToTL, userElligible } from "../helpers";


const cities = Object.keys(CitiesJson);

  const ArtirmaliSatinAlCart = (props)=>{

  //address types
  // const ind_add_type = 'individual';
  // const org_add_type = 'organizational';
  const location = useLocation();
  const paymentInfo = location.state;
  const event_info = paymentInfo.event_info[0];
  const event_id = event_info.id;

  //manage what component to display
  const [add_address, setadd_address] = useState(false);
  const [edit_address, setedit_address] = useState(false);
  const [showPaymentUI, setshowPaymentUI] = useState(false);

  const [selected_address, setselected_address] = useState({});
  const [all_addresses, setall_adresses] = useState([]);
  const [toggleFectchAddress, settoggleFectchAddress] = useState(false);
  const [previousAddressId, setpreviousAddressId] = useState(null)

  //Pul the following data from either local storage or navigate there 
  //mechanism thrown from the home page when satin al clicked
  const [etkinlikAdi, setEtkinlikAdi] = useState('');
  // const [etkinlikUcreti, setEtkinlikUcreti] = useState('');
  const [etkinlikTarihi, setEtkinlikTarihi] = useState('');
  const [etkinlikMekan, setEtkinlikMekan] = useState('');
  const [odemeTutari, setodemeTutari] = useState('');

  // const [event_id, setEvent_id] = useState('');
  const [user_id, setUser_id] = useState('');
  // const [ticket_id, setTicket_id] = useState('');
  const [user_email, SetUser_email] = useState('');
  const [visible, setVisible] = useState(false);
  const navigateThere = useNavigate();

const fetchEventInfo = ()=> {
  // setEvent_id(event_info.id);
  setEtkinlikAdi(event_info.etkinlik_adi);
  setodemeTutari(paymentInfo.payment_amount);
  setEtkinlikTarihi(event_info.etkinlik_tarihi);
  // setEtkinlikMekan(event_info.etkinlik_mekan);
  getBillnAddresses()
  
}



//get current user
const getUserInfo = () => {
  let user_id_ = JSON.parse(localStorage.getItem('user_id'));
  const results = getCurrentUser().then((response)=>{
    if (response.status===200) {
        const user_info = response.data.user;
        setUser_id(user_id_)         
    }

    // return response;
}).catch((errors)=>{return errors;});
}

//setup payment UI after address added or edited or selected
const readyForPayment = () => { 
  setadd_address(false);
  setedit_address(false);
  setshowPaymentUI(true);
  settoggleFectchAddress(!toggleFectchAddress)
 }

 //handle address selected
 const handleAddressSelected = (address_id) => { 
     let selectedAddrs = all_addresses.filter(item => ( item.id==address_id));
     setselected_address(selectedAddrs[0]);
 }
//Create prepayment entry
const  createPrepaymentRecord=  ()=> {

  const results = postPrepaymentData(
    {'event_id':event_id,
      'user_id':user_id,
      'billing_address_id':selected_address.id,
      'payment_amount':formatToNormal(odemeTutari) 
    }

    ).then((response)=>{
      if (response.status===200) {
        const orderInfo = {"order_id":response.data.order_id, "payment_amount":formatToTL(odemeTutari)};
        localStorage.setItem('order_id', JSON.stringify(response.data.order_id));
        navigateThere('/artirmali-odeme-yap',{"state":orderInfo});
      }
      }).catch((errors)=>{return errors;});

}

const handlePay = async (event) =>{ 
  
  event.preventDefault();
  generalPostMethod(USER_ELLIGIBLE,{event_id:event_id}).then((response) => { 
    if (response.status===200 && response.data.elligible) {
    createPrepaymentRecord();
    } else if(response.status===200 && !response.data.elligible) {
    alert("Bu Etkinlik için Satın Alma işlemi yapamazsınız. Lütfen diğer etkinliklerimizi takip edin")
    }else{
      alert("Kişi bilgileri kontrol edilememiş.")
    }
  })

  // const elligible = await userElligible(event_id)
  // userElligible(event_id).then((elligible) => { 
  //   if (elligible) {
  //     // console.log(JSON.stringify({full_name,last_name,user_email,event_id,user_id,
  //     //   fatura_address,phone, etkinlikUcreti}))
  //     // createPrepaymentRecord();
  //     console.log(elligible);
  //   } else {
  //     console.log(!elligible);

  //     // alert("Bu Etkinlik için Satın Alma işlemi yapamazsınız. Lütfen diğer etkinliklerimizi takip edin")
  //   }
  //  })
  
}

//function for triggring editing of address
const onEditAddress = (address_) => { 
  setselected_address(address_)
    setshowPaymentUI(false);
    setedit_address(true);
    setadd_address(false);
 }

 //function for starting new address
 function startNewAddress() {
  setshowPaymentUI(false);
  setedit_address(false);
  setadd_address(true);
 }

//get user billing addresses
const getBillnAddresses = () => { 
  generalGetMethos(FETCH_BILLING_ADDRESS_URL,{'event_id':event_id}).then(
    (response) => { 
      if (!response.status) {
        //set error message, something went wrong
      } else if(response.status===200) {
        if (response.data.addresses.length>0) {
          setall_adresses(response.data.addresses);
          setselected_address(response.data.addresses[0])
          setpreviousAddressId(response.data.previousAddressId)
          // readyForPayment();
          setadd_address(false);
          setedit_address(false);
          setshowPaymentUI(true);
        }else{
          setedit_address(false);
          setshowPaymentUI(false);
          setadd_address(true);
        }
        //set page to make payment
        // console.log('addresses', response.data);
      }
     }
  ).catch()
 }

 useEffect(() => { 
  getBillnAddresses();
  },[toggleFectchAddress])

useEffect(()=>{ 
  //   let loginVal = JSON.parse(localStorage.getItem('isLoggedIn'));
  // if(loginVal=='true'){setIsLoggedin('true')};  
  //Get current user info
  fetchEventInfo();
  getUserInfo();
  },[]);




    return(
      <div className="satin-all-page" style={{color:'white'}}>

            <div className=" satin-al-inner-block pb-[1px] ">
                <h5><u>Etkinlik Adı</u></h5>
                  <p>{etkinlikAdi}</p>
                  <div onClick={() => setVisible(!visible)}>
                  {!visible? (<p className='satin-al-details'>Detayları Gör.</p>):(<p className='satin-al-details'>Detayları Kapat.</p>)}
                  </div>
                  {visible?(<p visible={visible}>
                    Etkinlik Tarihi: <span style={{marginLeft:'10px', fontStyle:'italic',color:'#65c7e0'}}>{etkinlikTarihi}</span><br></br>
                    {/* Mekan: <span style={{marginLeft:'10px',fontStyle:'italic',color:'#65c7e0'}}>{etkinlikMekan}</span> <br></br> */}
                   
                </p>):(<></>)}
                  <hr/>
                  Sipariş Toplamı: <strong><span style={{marginLeft:'10px', color:'#65c7e0'}}>{formatToTL(odemeTutari)+" TL"}</span></strong> {/** */}
                   <br/><span style={{fontSize:'12px'}}>(%20 kdv dahil)</span> {/** */}
                  <hr/>
                  {/* address selection */}
                  {showPaymentUI&&
                  <div className="w-full flex mb-2 px-2  text-xs h-[2rem] ">
                    <p className="mx-0"
                     htmlFor="addrs_selector">Adresi Seç</p>
                    <select name="" id="addrs_selector"
                    
                    className="w-[60%] ml-auto rounded-md text-gray-600"
                    value={selected_address.id}
                    onChange={(event) => { handleAddressSelected(event.target.value) }}>
                      {all_addresses&&
                        all_addresses.map((item, index) => (
                          <option key={index} value={item.id}>{item.address_name}</option>
                        ))
                      }
                      {/* <option value="dd">ffff</option>
                      <option value="dd">ffff</option> */}
                    </select>
                    {/* add address button */}
                    <button
                     className="bg-blue-400 h-full mx-3 px-1 rounded-md
                     "
                     onClick={() => { startNewAddress() }}
                     >
                      Yeni Adres
                    </button>
                    
                  </div>
                  }

                {/* address display/edit/add area */}
                <div>
                
                 { edit_address&&<EditBillnAddress
                  props={{'address':selected_address,'readyForPayment':readyForPayment,}}/>}
                  { add_address&&<AddBillingAddress props={{'readyForPayment':readyForPayment}}/>}
                  { showPaymentUI&&<DisplayBillingAddress props={{'address':selected_address,
                    'onEditAddress':onEditAddress}}/>}
                
                </div>

                {/* check box */}
                {showPaymentUI&&
                <form action="" onSubmit={handlePay}>

                  <div className="p-1 flex">
                    <input type="checkbox" className="form-check-input" id="exampleCheck1" required/>
                    <label className="form-check-label ml-1" htmlForfor="exampleCheck1">
                    <a className="mr-1"
                    href={GET_EVENT_CONTRACT_FILE+paymentInfo.event_info[0].contract_file_url}
                          target="_blank" rel="noopener noreferrer">
                            Sözleşmeyi
                        </a>
                       okudum
                    </label>
                  </div> 

                  <button type="submit" 
                  className="btn btn-success">
                  Devam
                </button>
                </form>
                

                }              
            </div>
            {/* submit button */}
            
           
      </div>
    )
}

export default ArtirmaliSatinAlCart;