import { useLocation } from "react-router-dom";
import MyFooter from "../components/footer";
import Kvkk from "../components/kvkk";
import { useEffect } from "react";




const FooterPage1 = ()=>{

    const location = useLocation();
        // const id = location.state.id
    

    // useEffect(()=>{console.log(location.state.id)},[]);
    return(
        <div>
            <Kvkk/>
        </div>
    );



}

export default FooterPage1;