import React, { useState } from 'react'
import { EVENT_REQUEST_FORM_URL, generalFormPostMethod } from '../requests';
import { set } from 'date-fns';

const EventRequest = () => {
    const [errMsg, seterrMsg] = useState(null);

    const [formData, setFormData] = useState({
      social_media: '',
      social_media_username: '',
      number_of_followers: '',
      topic_of_posts: '',
      fullname: '',
      mobile_number: '',
      tel_number: '',
      event_province: '',
      event_date: ''
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      seterrMsg(null);
    //   Handle form submission here
    generalFormPostMethod(EVENT_REQUEST_FORM_URL,formData).then((response) => {
      if (response.status===200) {
          alert("Etkinlik İsteme Formu gönderildi!")
      }else{
        seterrMsg("Etkinlik İsteme Formu gönderilemedi!")
        }})     

    //   console.log('formData',formData);
    };


  return (
    <div className='flex mt-5 w-full justify-center items-center'>
         <div className='text-white'>
            <h2 className='mb-6 font-semibold'>Etkinlik İsteme Formu</h2>
            {errMsg && <p className='bg-red-400'>{errMsg}</p>}
            <form onSubmit={handleSubmit} className='space-y-2'>
            <div className='flex justify-around'>
                <label className='w-[10rem] text-start text-sm px-1'
                     htmlFor="social_media">Kullandığınız Sosyal Medya*:
                </label>
                <select name="social_media" id=""
                required
                    className="rounded-md text-gray-600 w-[13.4rem] "
                    value={formData.social_media} onChange={handleChange}
                    >
                    <option value="">Seç</option>
                    <option value="instagram">Instagram</option>
                    <option value="twitter">Twitter</option>
                    <option value="youtube">Youtube</option>
                    <option value="tiktok">Tiktok</option>
                </select>
                {/* <input className="rounded-md text-gray-600" 
                    type="text" id="social_media" name="social_media"
                    value={formData.social_media} onChange={handleChange} /> */}
            </div>

            <div className='flex justify-around'>
                <label className='w-[10rem] text-start text-sm px-1' htmlFor="social_media_username">Sosyal Medya Kullanıcı Adınız*:</label>
                <input required
                 className=" w-[13.4rem] rounded-md text-gray-600" type="text" id="social_media_username" name="social_media_username" value={formData.social_media_username} onChange={handleChange} />
            </div>

            <div className='flex justify-around'>
                <label className='w-[10rem] text-start text-sm px-1' htmlFor="number_of_followers">Takipçi Sayınız*:</label>
                <input required
                 className=" w-[13.4rem] rounded-md text-gray-600" type="number" id="number_of_followers" name="number_of_followers" value={formData.number_of_followers} onChange={handleChange} />
            </div>

            <div className='flex justify-around'>
                <label className='w-[10rem] text-start text-sm px-1' htmlFor="topic_of_posts">Paylaşımlarınızın İçeriği*:</label>
                <input required
                 className=" w-[13.4rem] rounded-md text-gray-600" type="text" id="topic_of_posts" name="topic_of_posts" value={formData.topic_of_posts} onChange={handleChange} />
            </div>

            <div className='flex justify-around'>
                <label className='w-[10rem] text-start text-sm px-1' htmlFor="fullname">Adınız Soyadınız*:</label>
                <input required
                 className=" w-[13.4rem] rounded-md text-gray-600" type="text" id="fullname" name="fullname" value={formData.fullname} onChange={handleChange} />
            </div>

            <div className='flex justify-around'>
                <label className='w-[10rem] text-start text-sm px-1' htmlFor="mobile_number">İletişim Mobil*:</label>
                <input required
                 className=" w-[13.4rem] rounded-md text-gray-600" type="text" id="mobile_number" name="mobile_number" value={formData.mobile_number} onChange={handleChange} />
            </div>

            <div className='flex justify-around'>
                <label className='w-[10rem] text-start text-sm px-1' htmlFor="tel_number">İletişim No*:</label>
                <input required
                 className=" w-[13.4rem] rounded-md text-gray-600" type="text" id="tel_number" name="tel_number" value={formData.tel_number} onChange={handleChange} />
            </div>

            <div className='flex justify-around'>
                <label className='w-[10rem] text-start text-sm px-1' htmlFor="event_province">Etkinlik Yapmak İstediğiniz İl:</label>
                <input required
                 className=" w-[13.4rem] rounded-md text-gray-600" type="text" id="event_province" name="event_province" value={formData.event_province} onChange={handleChange} />
            </div>

            <div className='flex justify-around'>
                <label className='w-[10rem] text-start text-sm px-1' htmlFor="event_date">Etkinlik Yapmak İstediğiniz Tarih:</label>
                <input required
                 className=" w-[13.4rem] rounded-md text-gray-600" type="text" id="event_date" name="event_date" value={formData.event_date} onChange={handleChange} />
            </div>

            <button type="submit"
            className='bg-blue-500 px-3 rounded-md mt-3'
            >
                Gönder</button>
            </form>
        </div>

    </div>
  )
}

export default EventRequest