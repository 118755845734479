import LoginForm from "./giris";
import MyFooter from "../components/footer";
import { CContainer } from "@coreui/react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@coreui/coreui/dist/css/coreui.min.css';



const LoginPage = (props) =>{

    return(
        <div>
        <div >
        {/* <CContainer> */}
        <LoginForm setIsLoggedin={props.setIsLoggedin}/>       
        {/* </CContainer> */}
      
       
        </div>
        {/* <MyFooter/> */}
        </div>
    );

    

}
export default LoginPage;