import axios from "axios";
import { useNavigate } from "react-router-dom";
import { handlePostLogout } from "../helpers";

//for toggling use credentials
export const withCredentialsValue = true;

//base urls
const DEV_BASE_URL = 'http://127.0.0.1:8000/api';
const PROD_BASE_URL = 'https://api.buradago.com/public/api';

//##########main based url to be used depending on whether in production or development mode]##########
// const CURRENT_MODE_CSRF_BASE_URL = 'http://127.0.0.1:8000';
// const CURRENT_MODE_BASE_URL = DEV_BASE_URL;

//uncomment the appropriate line based on whether deployin production or development
const CURRENT_MODE_CSRF_BASE_URL = 'https://api.buradago.com/public';
const CURRENT_MODE_BASE_URL = PROD_BASE_URL;

export const USER_ELLIGIBLE = CURRENT_MODE_BASE_URL+'/user-elligible';
export const USER_INFO_URL = CURRENT_MODE_BASE_URL+'/user';
export const GET_ETKINLIK_IMAGE_URL = CURRENT_MODE_BASE_URL+'/get-event-image?fileName=';
export const CHECK_MAX_PARTICIPANTS_REACHED_URL = CURRENT_MODE_BASE_URL+'/max_participants_reached';
export const LOGOUT_URL_PROD = CURRENT_MODE_BASE_URL+'/logout';  //For production
const GET_LEADER_BOARD_URL = CURRENT_MODE_BASE_URL+'/get-leader-board';
const GET_ALL_EVENTS_URL = CURRENT_MODE_BASE_URL+'/get-basic-events-info';
export const LOGIN_URL_PROD = CURRENT_MODE_BASE_URL+'/login';  //For production
export const AUTH_COOKIE_LINK = CURRENT_MODE_CSRF_BASE_URL+'/sanctum/csrf-cookie';
const SEND_VERIFICATION_CODE_URL= CURRENT_MODE_BASE_URL+'/send-instagram-verification-code';
const VERIFY_USER_URL = CURRENT_MODE_BASE_URL+'/verify-user';
const GET_SINGLE_EVENT = CURRENT_MODE_BASE_URL+'/get-single-event-info';
export const REGISTER_URL_PROD = CURRENT_MODE_BASE_URL+'/register';
export const ISLOGGEDIN_URL = CURRENT_MODE_BASE_URL+'/isloggedin';


//PAYMENTS
export const PAYMENT_DATA_POST_URL = CURRENT_MODE_BASE_URL+'/prepayment-post';
export const PAYMENT_LINK_GENERATOR_URL = CURRENT_MODE_BASE_URL+'/get-iframe-url'; //prod
export const PAYMENT_3dLINK_GENERATOR_URL = CURRENT_MODE_BASE_URL+'/get-3diframe-url'; //prod
const GET_USER_PAYMENTS_URL = CURRENT_MODE_BASE_URL + '/get-user-payments';
export const GET_USER_CARDS_URL = CURRENT_MODE_BASE_URL + '/paymentcards';

//PASSWORD STUFF
export const PASSWORD_RESET_MAIL_URL = CURRENT_MODE_BASE_URL+'/password/reset/email';
export const PASSWORD_RESET_URL = CURRENT_MODE_BASE_URL+'/password/reset';

//EVENT OWNER
export const MY_EVENTS_URL = CURRENT_MODE_BASE_URL+'/get-my-events'; 
export const GET_EVENTS_BY_OWVER_NAME = CURRENT_MODE_BASE_URL+'/get-events-by-ownername?nm='; 
// export const APPROVE_ATTENDEE_URL = CURRENT_MODE_BASE_URL+'/approve-attendee';  
export const DISAPPROVE_ATTENDEE_URL = CURRENT_MODE_BASE_URL+'/eventowner-disapprove-attendee';  
export const APPROVE_ATTENDEE_URL = CURRENT_MODE_BASE_URL+'/eventowner-approve-attendee';  
// export const GET_ATTENDANTS_LIST = CURRENT_MODE_BASE_URL+'/get-attendees';
export const GET_ATTENDANT_INFO = CURRENT_MODE_BASE_URL + '/get-attendant-info';
export const GET_EVENT_OWNER_ATTENDEES = CURRENT_MODE_BASE_URL+'/get-eventowner-attendees'; 
export const GET_USER_INSTAGRAM = CURRENT_MODE_BASE_URL+'/get-user-instagram?user_id=';
export const EVENT_REQUEST_FORM_URL = CURRENT_MODE_BASE_URL+'/requestedevents';


//for billing address
export const INSERT_BILLING_ADDRESS_URL = CURRENT_MODE_BASE_URL + '/insert-billing-address';
export const FETCH_BILLING_ADDRESS_URL = CURRENT_MODE_BASE_URL + '/fetch-billing-addresses';
export const UPDATE_BILLING_ADDRESS_URL = CURRENT_MODE_BASE_URL + '/update-billing-address';
export const DELETE_BILLING_ADDRESS_URL = CURRENT_MODE_BASE_URL + '/delete-billing-address';

//billing
export const UPLOAD_INVOICE_URL = CURRENT_MODE_BASE_URL+'/billing/upload-invoice';

//comments
export const CREATE_COMMENT = CURRENT_MODE_BASE_URL+'/comments/create';
export const GET_EVENT_OWNER_RELATED_COMMENTS = CURRENT_MODE_BASE_URL+'/comments/event-owner-related';
export const GET_EVENT_RELATED_COMMENTS = CURRENT_MODE_BASE_URL+'/comments/event-related';

//tickets/qrcodes
export const GET_USER_TICKETS = CURRENT_MODE_BASE_URL+'/event/tickets/user';
export const GET_QRCODE_URL = CURRENT_MODE_BASE_URL+'/qrcode?file_name=';
//messages for creating and fetcting messages
export const MESSAGES_URL = CURRENT_MODE_BASE_URL+'/messages';

//miscellaneous
export const GET_EVENT_CONTRACT_FILE = CURRENT_MODE_BASE_URL+'/get-event-contract?fileName='


//Ably client credential. Note this credentials can only be used to listen on channels
//cant publish messageALL THESE SHOULD BE IN ENVIRONMENT VARIABLE ON THE SERVER
const ABLY_CHANNEL_API_KEY ='VH5AJA.xSbX3A:9qw0uPXVRPv5J5zYyEi3KHQEhmMHApMzM2l6P1lC8yI';
const ABLY_APP_NAME = "buradago";





//define function to create broadcast channel url using event id
export function createBroadCastUrl(event_id) {
  var ABLY_CHANNEL_URL ='https://realtime.ably.io/event-stream?channels='+ABLY_APP_NAME+event_id+'&v=1.2&key=' + ABLY_CHANNEL_API_KEY;
  return ABLY_CHANNEL_URL;
}

//get a single event info 
//also for display on single event page with eventID
export const getSingleEvent = (payload)=>{
    const result = axios.post(GET_SINGLE_EVENT,payload,{
        headers:{'Content-Type':'application/json',
        'Accept':'application/json'},
        withCredentials: withCredentialsValue
    }).then((response)=>{
        return response;
    }).catch((errors)=>{return errors;})
    
    return result;
}

//get all evenets for home page display including pics
export const getAllEvents = ()=>{
    
    const result = axios.get(GET_ALL_EVENTS_URL,{
        headers:{'Content-Type':'application/json',
        'Accept':'application/json'},
        withCredentials: withCredentialsValue
    }).then((response)=>{
        return response;
    }).catch((errors)=>{return errors;})
    
    return result;
}

//get a single event for display on single event page with eventID
// export const getSingleEvent = (payload)=>{
    
//     const result = axios.get(GET_ALL_EVENTS_URL,payload,{
//         headers:{'Content-Type':'application/json',
//         'Accept':'application/json'},
//         withCredentials: withCredentialsValue
//     }).then((response)=>{
//         return response;
//     }).catch((errors)=>{return errors;})
    
//     return result;
// }

export const sendVerificationCode = (payload)=>{
    const result = axios.post(SEND_VERIFICATION_CODE_URL,payload,{
        headers:{'Content-Type':'application/json',
                'Accept':'application/json'},
        withCredentials: withCredentialsValue
    }).then((response)=>{
        
        return response;
    }).catch((errors)=>{return errors;})

    return result;
}

export const verifyUser = (payload)=>{
    const result = axios.post(VERIFY_USER_URL,payload,{
        headers:{'Content-Type':'application/json',
                'Accept':'application/json'},
        withCredentials: withCredentialsValue
    }).then((response)=>{
        return response;
    }).catch((errors)=>{return errors;})
    return result;

}

//Artirmali satis / bidding orders
 export const getLeaderBoard = (payload)=>{
    const result = axios.post(GET_LEADER_BOARD_URL,payload,{
        headers:{'Content-Type':'application/json',
                'Accept':'application/json'},
        withCredentials: withCredentialsValue
    }).then((response)=>{
        return response;
    }).catch((errors)=>{return errors;});
    return result;
}

//Create prepayment entry
export const postPrepaymentData= (payload)=> {
  const results = axios.post(PAYMENT_DATA_POST_URL,payload,
      {
          headers: { 'Content-Type': 'application/json',
          'Accept':'application/json' },
          withCredentials: withCredentialsValue
      }
  ).then((response)=>{
    return response;
  }).catch((errors)=>{return errors;});
  return results;
}

//get current/logged in user info
export const getCurrentUser=() => {
  
  const results = axios.get(USER_INFO_URL,
    {
        headers: { 'Content-Type': 'application/json',
        'Accept':'application/json' },
        withCredentials: withCredentialsValue
    }).then((response)=>{
      return response;
  }).catch((errors)=>{return errors;});
  return results;
}

//get user payments
export const getUserPayments=(payload) => {
  
  const results = axios.post(GET_USER_PAYMENTS_URL,payload,
    {
        headers: { 'Content-Type': 'application/json',
        'Accept':'application/json' },
        withCredentials: withCredentialsValue
    }).then((response)=>{
      return response;
  }).catch((errors)=>{return errors;});
  return results;
}

//EVENT OWNER

//disapprove attendee
export const disapproveAttendee = (payload)=>{

	const result = axios.post(DISAPPROVE_ATTENDEE_URL,payload,
		 {
			headers: { 'Content-Type': 'application/json',
		  'Accept':'application/json',
		  // 'Access-Control-Allow-Credentials':true
		 	},
			
			withCredentials: withCredentialsValue})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});

	return result;
}

//approve attendee
export const approveAttendee = (payload)=>{

	const result = axios.post(APPROVE_ATTENDEE_URL,payload,
		
		{	
			headers: { 'Content-Type': 'application/json',
			'Accept':'application/json',
			// 'Access-Control-Allow-Credentials':true
			},
			
			withCredentials: withCredentialsValue})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});

	return result;

}

//axios.get all event
export const getMyEvents = ()=>{

	const result = axios.get(MY_EVENTS_URL,
		
		{
			headers: { 'Content-Type': 'application/json',
		  'Accept':'application/json',
		  // 'Access-Control-Allow-Credentials':true
		 	},

			withCredentials: withCredentialsValue})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});

	return result;
}

//axios.get all event
export const getEventOwnerAttendees = (payload)=>{

	const result = axios.post(GET_EVENT_OWNER_ATTENDEES,payload,
		 {
			headers: { 'Content-Type': 'application/json',
		  'Accept':'application/json',
		  // 'Access-Control-Allow-Credentials':true
		 	},

			withCredentials: withCredentialsValue})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});

		return result;

}

//get an attendants info
export const getAttendantInfo = (payload)=>{

	const result = axios.post(GET_ATTENDANT_INFO,payload,
		 {
			headers: { 'Content-Type': 'application/json',
		  'Accept':'application/json',
		  // 'Access-Control-Allow-Credentials':true
		 	},
			withCredentials: withCredentialsValue})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});

		return result;
}

//general get method
export const generalGetMethos = (url, payload=null)=>{

	const result = axios.get(url, 
		 {
			params:payload,

			headers: { 'Content-Type': 'application/json',
		  'Accept':'application/json',
		  // 'Access-Control-Allow-Credentials':true
		 	},
			withCredentials: withCredentialsValue})
		.then((response) => {
			if(response.status === 470){
				handlePostLogout();
			}
			return response;
		})
		.catch((error) => {
			if(error.response.status === 470){
				console.log("error.response.status",error);
				
				handlePostLogout();
			}
			return error.response;
		});

		return result;
}

//general post method
export const generalPostMethod = (url,payload)=>{

	const result = axios.post(url,payload,
		 {
			headers: { 'Content-Type': 'application/json',
		  'Accept':'application/json',
		  // 'Access-Control-Allow-Credentials':true
		 	},
			withCredentials: withCredentialsValue})
		.then((response) => {
			if(response.status === 470){
				handlePostLogout();
			}
			return response;
		})
		.catch((error) => {
			if(error.response.status === 470){
				handlePostLogout();
			}
			return error.response;
		});

		return result;
}

//general post method with formdata/multipart form
export const generalFormPostMethod = (url,payload)=>{

	const result = axios.post(url,payload,
		 {
			headers: {
			 'Content-Type': 'multipart/form-data',
		  'Accept':'application/json',
		  // 'Access-Control-Allow-Credentials':true
		 	},
			withCredentials: withCredentialsValue})
		.then((response) => {
			if(response.status === 470){
				handlePostLogout();
			}
			return response;
		})
		.catch((error) => {
			if(error.response.status === 470){
				handlePostLogout();
			}
			return error.response;
		});

		return result;
}

//upload fatura
export const uploadInvoice = (payload)=>{
	let result = axios.post(UPLOAD_INVOICE_URL,payload,
		{headers:{
			'Content-Type':'multipart-form/form-data',
			'Accept':'application/json'},
		 withCredentials: withCredentialsValue})
		.then((response)=>{
			// console.log('response in requests',response)
			return response;
		})
		.catch((error)=>{
			return error.response;
		});
	return result;
}
