import { useLocation } from "react-router-dom";
import MyFooter from "../components/footer";
import SiteKullanim from "../components/site-kullanim";
import { useEffect } from "react";




const FooterPage = ()=>{

    const location = useLocation();
        // const id = location.state.id
    

    // useEffect(()=>{console.log(location.state.id)},[]);
    return(
        <div>
            <SiteKullanim/>
        </div>
    );



}

export default FooterPage;