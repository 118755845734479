import "../App.css";
import MyFooter from "../components/footer";
import ArtirmaliSatis from "../components/artirmali-satis";


const ArtirmaliSatisSayfasi = () =>{

    return(
        <div className="homebody">
        <div className="mx-4">

        <ArtirmaliSatis/>
        
        </div>

        </div>
    );
}

export default ArtirmaliSatisSayfasi;
