import { useEffect, useState } from 'react';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CContainer,CRow,CCol, CHeaderDivider, CFormLabel  } from '@coreui/react';
import PastEventsTable from '../user-past-event';
import axios from 'axios';
import user_avatar from "../../user_avatar.jpg"
import MyFooter from '../footer';
import { USER_INFO_URL } from '../requests';
import { useNavigate } from 'react-router-dom';




const UserAdminPage = ()=>{

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [nick, setNick] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [instagram, setInstagram] = useState('');
    const [dateCreated, setDateCreated] = useState('');
    const [profilePic, setprofilePic] = useState("");

    var account_verified = null;
    if (JSON.parse(localStorage.getItem('verified'))) {
        account_verified = JSON.parse(localStorage.getItem('verified'));
    }

    const navigateThere = useNavigate();

    const fetchUserInfo = async ()=>{
        try {
            const response = axios.get(USER_INFO_URL,
            // JSON.stringify({ email,password }),
            {
                headers: { 'Content-Type': 'application/json',
                'Accept':'application/json' },
                withCredentials: true
            }).then((response)=>{
                if (response.status===200) {
                    const user_info = response.data.user;
                    const profileP= response.data.profile_pic_url;
                    //update user info states
                    // var parsedUser = JSON.parse(response?.data.user)
                    setFirstName(user_info.first_name)
                    setLastName(user_info.last_name)
                    setNick(user_info.nick)
                    setEmail(user_info.email)
                    setPhone(user_info.phone)            
                    setInstagram(user_info.instagram)
                    setDateCreated(user_info.created_at)

                    if (profileP.length>0) {
                        setprofilePic(profileP[0].profile_pic_url)
                    }
                }

                return response;
            }).catch((errors)=>{return errors;});
             console.log(response)
            

            
            // console.log("PasedUserInfo object",parsedUser)
            // console.log("FirstName state is: ",firstName)


            // }
            //  console.log(response?.data);
    
        } catch (err) {
                if (!err?.response) {
                console.log('There was an error');
                // setErrorMessage('No Server Response');
                      } 

                }
    }

    useEffect(
        ()=>{fetchUserInfo()}
    ,[]);

    return (
        <div>
        <div class="container homebody bootstrap snippets bootdey">
            {account_verified==='no'?
            <p className='bg-green-300 w-full p-0'>
                Hesabınızı doğrulamak için
                     <span
                     onClick={() => { navigateThere('/dogrulama') }}
                      className='cursor-pointer text-blue-500 pl-2'>buraya tıklayın.</span>
             </p>
            :<></>}
            <div class="panel-body inf-content">
                <div class="row justify-content-around">
                    <div class="col-md-4">
                        <img alt="" style={{ width: '600px' }} title=""
                         className='rounded-full' src={profilePic} data-original-title="Usuario" />
                        <p className='text-danger'>Not: Profil fotonuzu değiştirdiğinizde, sistem otomatik olarak güncelleyecektir.</p>
                        <ul title="Ratings" class="list-inline ratings text-center">
                            <li><a href="#"><span class="glyphicon glyphicon-star"></span></a></li>
                            <li><a href="#"><span class="glyphicon glyphicon-star"></span></a></li>
                            <li><a href="#"><span class="glyphicon glyphicon-star"></span></a></li>
                            <li><a href="#"><span class="glyphicon glyphicon-star"></span></a></li>
                            <li><a href="#"><span class="glyphicon glyphicon-star"></span></a></li>
                        </ul>
                    </div>
                    <div class="col-md-6">
                        <strong style={{ color: 'white' }}>Bilgilerim</strong><br />
                        <div class="table-responsive">
                            <table class="table table-user-information" style={{borderRadius:'10px',overflow:'hidden'}}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <strong>
                                                <span class="glyphicon glyphicon-asterisk text-primary"></span>
                                                İnstagram
                                            </strong>
                                        </td>
                                        <td class="text-primary">
                                            {(instagram && instagram)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>
                                                <span class="glyphicon glyphicon-user  text-primary"></span>
                                                Ad
                                            </strong>
                                        </td>
                                        <td class="text-primary">
                                        {(firstName && firstName)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>
                                                <span class="glyphicon glyphicon-cloud text-primary"></span>
                                                Soyad
                                            </strong>
                                        </td>
                                        <td class="text-primary">
                                        {(lastName && lastName)}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <strong>
                                                <span class="glyphicon glyphicon-bookmark text-primary"></span>
                                                Nick
                                            </strong>
                                        </td>
                                        <td class="text-primary">
                                        {(nick && nick)}
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>
                                            <strong>
                                                <span class="glyphicon glyphicon-eye-open text-primary"></span>
                                                Mail Adresi
                                            </strong>
                                        </td>
                                        <td class="text-primary">
                                        {(email && email)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>
                                                <span class="glyphicon glyphicon-envelope text-primary"></span>
                                                Tel No
                                            </strong>
                                        </td>
                                        <td class="text-primary">
                                        {(phone && phone)}
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td>
                                            <strong>
                                                <span class="glyphicon glyphicon-calendar text-primary"></span>
                                                Modified
                                            </strong>
                                        </td>
                                        <td class="text-primary">
                                            20 jul 20014 20:00:00
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='row justify-content-center'>


                    <CRow className='row justify-content-center'>
                        <div className="row justify-content-center" style={{ marginTop: '30px' }}>


                            <CCol sm xs={12}><h3 style={{ color: 'white' }}>Katıldığım Etkinlikler</h3>
                                <PastEventsTable />

                            </CCol>
                            <CCol sm xs={12}>
                                <CRow>
                                    <h3 style={{ color: 'white' }}>Yorumlarım</h3>
                                </CRow>
                                <CRow>
                                    {/* <CCol className='.2'>Etkinlik</CCol> */}
                                    <CCol>

                                        <p className='yorumlarim'>
                                            {/* Katıldığım etkinlik harikaydı! Etkinlik adı ile Detaylar
bölümleri oldukça ilgi çekiciydi ve organizasyon mükemmel
şekilde düzenlenmişti. Tek kelimeyle harika bir deneyimdi, tekrar katılmak isterim! */}
                                        </p>
                                        <p className='yorumlarim'>
                                            {/* Katıldığım etkinlik harikaydı! Etkinlik adı ile Detaylar
bölümleri oldukça ilgi çekiciydi ve organizasyon mükemmel
şekilde düzenlenmişti. Tek kelimeyle harika bir deneyimdi, tekrar katılmak isterim! */}
                                        </p>
                                        <p className='yorumlarim'>
                                            {/* Katıldığım etkinlik harikaydı! Etkinlik adı ile Detaylar
bölümleri oldukça ilgi çekiciydi ve organizasyon mükemmel
şekilde düzenlenmişti. Tek kelimeyle harika bir deneyimdi, tekrar katılmak isterim! */}
                                        </p>
                                        <p className='yorumlarim'>
                                            {/* Katıldığım etkinlik harikaydı! Etkinlik adı ile Detaylar
bölümleri oldukça ilgi çekiciydi ve organizasyon mükemmel
şekilde düzenlenmişti. Tek kelimeyle harika bir deneyimdi, tekrar katılmak isterim! */}
                                        </p>
                                    </CCol>
                                </CRow>
                                <CRow></CRow>
                                <CRow></CRow>

                            </CCol>
                            {/* <CCol><Etkinlik reactImage={tarkan}/></CCol> */}

                        </div>

                        {/* <Etkinlik /> */}
                    </CRow>


                </div>
            </div>
        </div>
        </div>
    );
}

export default UserAdminPage;