import '@coreui/coreui/dist/css/coreui.min.css';
import { CCard, CCardImage,CCardBody,CCardTitle,CCardText,CButton,CCardHeader,CCollapse,CContainer  } from '@coreui/react';
import { useEffect, useState } from 'react';

// import logo from '../logo.svg';
// import tarkan from '../tarkan.jpg';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { CHECK_MAX_PARTICIPANTS_REACHED_URL, generalGetMethos, GET_ETKINLIK_IMAGE_URL } from './requests';
import { countdown, createDateObj, formatToTL, getSaleTimeStampFromEvent, getTimeStampFromEvent } from '../helpers';
import { set } from 'date-fns';
// import { CDBCollapse } from 'cdbreact';








const EtkinlikGeneral = (props)=> {
    const [visible, setVisible] = useState(false);

    const [etkinlikAdi, setEtkinlikAdi] = useState('');
    const [etkinlikUcreti, setEtkinlikUcreti] = useState('');
    const [etkinlikKodu, setEtkinlikKodu] = useState(null);
    const [kisiSayisi, setKisiSayisi] = useState('');
    const [etkinliktarihi, setetkinliktarihi] = useState('');
    const [saat, setSaat] = useState('');
    const [showBuyButton, setshowBuyButton] = useState(false);
    const [countdowndata, setcountdowndata] = useState({})
    const navigateThere = useNavigate();
    const [errMessage, seterrMessage] = useState("");
    // const [showMaxParticipantsPopup, setshowMaxParticipantsPopup] = useState(false);

    

    // returns true (showbutton) if purchase end time of event is still not due yet
    function determineBuyButton(event,timestamp) {
       
        //get sale end and sale start timestamp
        let saleEndTimeStamp = getSaleTimeStampFromEvent(event,"end")
        let saleStartTimeStamp = getSaleTimeStampFromEvent(event,"start")
        // return saleEndTimeStamp;
        let event_timestamp = saleEndTimeStamp // new Date(createDateString(event.satis_bitis_tarihi)+'T'+event.s_bisaat+":00");
        let current_stimestamp = null;
        let onSale = false;

        if (timestamp) {
            current_stimestamp = new  Date(timestamp)
            //if event is on sale
            if (current_stimestamp>=saleStartTimeStamp && current_stimestamp <saleEndTimeStamp)
             {
                onSale = true
             } 

            return onSale
        }
   
        return  false
    }

    //check for non-bidding event if max participants is reached
    function checkMaxParticipantsReached(event_id) {
        
        const participation = JSON.parse(localStorage.getItem('participation'));
        
        if (! participation.event_id) {
            return false            
        } else if (participation[event_id] < props.props.katilim_sayisi) {
            return false
        }else{
            return true
        }
        
    }

    useEffect(()=>{
        // console.log("event details ",props.props)
        setshowBuyButton(
        determineBuyButton(props.props,props.server_timestamp)
        )

        // console.log('buyButton ',determineBuyButton(props.props,props.server_timestamp));
        setEtkinlikAdi(props.props.etkinlik_adi)
        setEtkinlikUcreti(props.props.katilim_fiyati)
        setEtkinlikKodu(props.props.id)
        setKisiSayisi(props.props.katilim_sayisi)
        setetkinliktarihi(props.props.etkinlik_tarihi)
        setSaat(props.props.saat)

    },[])

    //useefect for the time chronometer/countdown
    useEffect(() => 
    {
        const target_timestamp = createDateObj(props.props.satis_bitis_tarihi,
            props.props.s_bisaat)

        const interval = setInterval(() => {
        const cntDnData = countdown(target_timestamp) //starter_milSeconds)

        setcountdowndata(cntDnData)
        }, 600); // 60000 milliseconds = 1 minute
    
        // Clear the interval when the component unmounts
        return () => clearInterval(interval);
    }, []);

    const handleSatinAlClick = ()=>{
        let user_id = null;
        let account_verified = null;
        const only_verified_account = props.props.only_verified_account;
        

        let loginVal = JSON.parse(localStorage.getItem('isLoggedIn'));

        if (JSON.parse(localStorage.getItem('isLoggedIn'))) {
            // user_id = JSON.parse(localStorage.getItem('isLoggedIn'));
            user_id = JSON.parse(localStorage.getItem('user_id'));
        }
        //get account verification status
        if (JSON.parse(localStorage.getItem('verified'))) {
            account_verified = JSON.parse(localStorage.getItem('verified'));
        }

        //prevent purchase if user is unverified and event requires verification
        // NOTE: backend already blocks this too
        if (loginVal==='true') {    
            const event_type = props.props.etkinlik_sekil;
            if (account_verified==='no' && only_verified_account==='true') {
                // seterrMessage("Bu etkinliğin bileti alabilmek için hesabinizin\
                // doğrulanması gerekir.");

                alert("Bu etkinliğin bileti alabilmek için hesabinizin doğrulanması gerekir.");
                console.log("verify account first");
            }
            //check if event type is sabit and max participants is reached
            else if (event_type=== "sabit" && checkMaxParticipantsReached(props.props.id)) {
                props.setshowMaxParticipantPopup(true)
            }
            else if (event_type==="artirmali") {
                navigateThere('/artirmali-satis',{"state":{"event_id":props.props.id}});
                
            } else {//if event type is sabit (after not meeting all the above requirements)
                const data = {user_id: user_id, event_info:[props.props], payment_amount:props.props.katilim_fiyati}
                navigateThere('/artirmali-satinal-sayfasi',{state:data}) //pass event id and amout to pay
            }

        }else{
            navigateThere('/giris');
        }
        
    }

    return(
        <div>
                            
            <CCard style={{ width: '20rem',marginBottom:'30px'}}>
            <CCardHeader>
                {showBuyButton&&
                    <p className='text-[0.6rem] my-0 space-x-3 bg-red-200 rounded-md'>
                        Satış için kalan süre:
                        <span className="ml-1">{countdowndata.days} Gün</span>
                        <span className="ml-1">{countdowndata.hours} Saat</span>
                        <span className="ml-1">{countdowndata.minutes} Dakıka</span>
                        <span className="ml-1">{countdowndata.seconds} Saniye</span>
                    </p>
                }
                Etkinlik tarihi: <span className='ms-1'>{etkinliktarihi}</span>
            </CCardHeader>
            <div style={{minHeight:'325px',maxHeight:'325px'}}
             className=' d-flex'>
            <CCardImage orientation="bottom"
            style={{minHeight:'325px',maxHeight:'325px'}}
             className='align-self-center '
            src={GET_ETKINLIK_IMAGE_URL+props.props.event_pic_url} //{props.props.profile_pic_uri}
            />
              </div>
             {/* style={{maxHeight:'367px'}} /> */}
            <CCardBody>
            <CCardTitle style={{fontSize:'17px',height:'35px'}}>{etkinlikAdi}</CCardTitle>
            <CCardText onClick={() => setVisible(!visible)}>
             {!visible? (<p className='footer-text mx-auto w-fit px-2 rounded-md'>Detayları Gör.</p>):(<p className='footer-text'>Detayları Kapat.</p>)}
            </CCardText>
            
                {visible?(<p visible={visible}>
                    {props.props.etkinlik_sekil==='sabit'&&
                    <strong>
                        Katılım Ücreti: 
                        <span className='ms-1'>{etkinlikUcreti&&formatToTL(etkinlikUcreti)+' '+'TL'}</span>
                        {/* <span className='ms-1'>TL</span> */}
                    </strong> 
                    }
                    <br></br>
                    Katılacak Kişi Sayısı: 
                      <strong> 
                      <span className='ms-1'>{kisiSayisi}</span>
                      </strong> <br></br>
                    Etkinlik Kodu :<span className='ms-1'>{etkinlikKodu}</span> <br></br>                    
                    Saat: <span className='ms-1'>{saat}</span> <br></br>
                    <span
                    onClick={() => { props.setevenRules(props.props.event_rules);
                                     props.setOpenModal(true)
                     }}
                     className="bg-blue-300 text-xs rounded-md px-1 cursor-pointer">
                        Etkinlik Kuralları
                    </span>
                   
                </p>):(<></>)}

            {showBuyButton?
            <CButton onClick={handleSatinAlClick} >Satın al</CButton>
                :
            <p className="bg-red-200 mx-auto w-fit px-2 rounded-md text-sm" >Satış Sona Erdi!</p>
            }
            </CCardBody>
        </CCard>

        
          

        
        
        </div>
    );
}

export default EtkinlikGeneral;