import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link, useBeforeUnload } from 'react-router-dom';
import axios from "axios";
import { withRouter, useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';

import FenomenMenu from './fenomen-menus';
// import favicon from '../favicon.png'
import favicon from '../favicon.jpeg';
import headOnly from '../headOnly.png';
import { generalGetMethos, ISLOGGEDIN_URL, LOGOUT_URL_PROD } from './requests';
import { handleLogout, handlePostLogout } from '../helpers';
import { ur } from '../config';


function Header(props) {

  //Handle navbar openClose
  const [menuOpen, setMenuOpen] = useState(false)
  const toggleMenu = () => {
    // console.log("First Click",menuOpen)
    setMenuOpen(!menuOpen)

    // console.log("second Click",menuOpen)
  }

  const handleClose = () => setMenuOpen(false)
  const scrollTop = ()=>{window.scrollTo(0, 0)}
  const [userType, setUserType] = useState('fffff');


//Destroy login onWindow/Browser close
// useBeforeUnload(()=>{
//   // setIsLoggedin('false')
//   localStorage.setItem('isLoggedIn','false')
// });
    
  return (
    <>
      {['sm', ].map((expand) => (
        <Navbar key={expand} expand={expand}
         fixed='top' bg={'dark'}
         collapseOnSelect
          className="bg-body-tertiary mb-4">
          <Container fluid>
            <Navbar.Brand Link as={Link} to="/"><img src={favicon} width="70" height="40" alt='Buradago_logo'></img> </Navbar.Brand>

            {/* <Navbar.Brand href="#"> <img src={favicon} width="70" height="40"></img> </Navbar.Brand> */}
            
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`}
            onClick={toggleMenu}
             />
            <Navbar.Offcanvas
              restoreFocus={false}
              // show={menuOpen}
              // autoFocus={false}
              // onExited={scrollTop}
              
              onExit={scrollTop}
              
              
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Buradago
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3"  >
                  {props.userType===ur.e_owner?
                  <Nav.Link eventKey="1" as={Link} to="/fenomen-admin"className=''
                  //  onClick={() => { toggleMenu() }}
                    >Anasayfa</Nav.Link>
                    :
                    <Nav.Link as={Link} to="/"
                    // note: event key is necessay for "collapseOnSelect" to work
                    eventKey="1"
                    className=''
                   onClick={handleClose}
                    >Anasayfa</Nav.Link>
                  }
                  {/* if user is an event owner (type 9) */}
                  {props.userType===ur.e_owner?(<FenomenMenu expand/>):(<></>)}
                  
                  {props.isLoggedin==='true'?(<></>)
                  :(<Nav.Link as={Link} onClick={handleClose} eventKey="6" to="/giris">Giriş</Nav.Link>)}

                  {props.isLoggedin==='true'?(<></>)
                  :(<Nav.Link as={Link} onClick={handleClose} eventKey="7" to="uye-ol-page">Üye Ol</Nav.Link>)}
                 
                  {props.isLoggedin=='true'?
                    (<NavDropdown
                      title="Hesabım"
                      id={`offcanvasNavbarDropdown-expand-${expand}`}
                    >
                      <NavDropdown.Item
                      eventKey="2"
                      Link as={Link} onClick={handleClose}
                        to="/user-admin">
                          Profilim
                      </NavDropdown.Item>

                      <NavDropdown.Item
                      eventKey="3"
                      Link as={Link} onClick={handleClose}
                        to="/my-addresses">
                          Adreslerim
                      </NavDropdown.Item>

                      <NavDropdown.Item
                      eventKey="4"
                      Link as={Link} onClick={handleClose}
                        to="/my-tickets">
                          Biletlerim
                      </NavDropdown.Item>
                    
                      {/* <NavDropdown.Divider /> */}
                      <NavDropdown.Item
                      eventKey="5"
                       onClick={handleLogout}>
                        Çıkış
                      </NavDropdown.Item>
                    </NavDropdown>)
                  :
                     (<></>)}
                </Nav>
                {/* comment search field for now */}
                {/* <Form className="d-flex">
                  <Form.Control
                    type="search"
                    placeholder="Etkinlik Ara"
                    className="me-2"
                    aria-label="Search"
                  />
                  <Button variant="outline-success">Ara</Button>
                </Form> */}
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default Header;