// import React from "react"
import { Nav, NavItem } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import Header from "./header";
import { useContext } from "react";
import HeaderLoggedIn from "./header-loggedIn";
import MyFooter from "./footer";



const Layout = (props) =>{
// const {isLogin} = useContext(isLoginContext); 


 return(   
<div className="App">
<header className="App-header">
{/* {isLogin?<HeaderLoggedIn/>:<Header />} */}
<Header isLoggedin={props.isLoggedin} setIsLoggedin={props.setIsLoggedin}
userType={props.userType} setUserType={props.setUserType}/>
  
</header>
<Outlet />
<MyFooter/>
</div>
 );
 
}

export default Layout;