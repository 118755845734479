import Fenomen from "../components/fenomen";
import "../App.css";
import MyFooter from "../components/footer";


const FenomenAdmin = () =>{

    return(
        <div>
        <div className="homebody">

        <Fenomen/>
        
        </div>

        </div>
    );
}

export default FenomenAdmin;
