
import { useEffect, useState } from "react";
import "../App.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { PAYMENT_LINK_GENERATOR_URL } from "../components/requests";

// const PAYMENT_LINK_GENERATOR_URL = 'http://127.0.0.1:8000/api/get-iframe-url'; //dev


const MakePayment = ()=>{
    // const location = useLocation();
    // const orderInfo = location.state;

    const [iframeSrc, setIframeSrc] = useState('');
    const [etkinlikUcreti, setEtkinlikUcreti] = useState('');
    const [orderId, setOrderId] = useState('');

    const navigateThere = useNavigate();


    const fetchUserInfo = ()=> {


  //retrieve and fill up etkinlik info
//   let etkinlik_name = JSON.parse(localStorage.getItem('etkinlikAdi'));
  const etkinlik_price = JSON.parse(localStorage.getItem('etkinlikUcreti'));

  setEtkinlikUcreti(etkinlik_price);

//   setEtkinlikTarihi(etkinlik_date);
//   setEtkinlikMekan(etkinlik_venue);

}


    const getIframeLink = async ()=>{

        //NOTE: AT THE BACKEND BEFORE USINF THE ETKINLIK UCRETI DO THIS
        //USE HE PHP FUNCTION IN BS TO FORMAT IT INTO TUKISH MONEY QUOTING
        //THEN REMOVE FULLSTOPS 
        //THEN REMOVE COMMAS

        //basically you just borrow all of that code from the checkout page in BS.


            const order_id = JSON.parse(localStorage.getItem('order_id'));
            // console.log("order id is: ",order_id)
            const results = axios.post(PAYMENT_LINK_GENERATOR_URL,
            JSON.stringify({ 'etkinlikUcreti':etkinlikUcreti,'orderId':order_id}),
            {
                headers: { 'Content-Type': 'application/json',
                'Accept':'application/json' },
                withCredentials: true
            } ).then(
                (response) => {
                    if (response.status===200) {                        
                        setIframeSrc(response.data.iframe_url);
                    }
                }
            );
            //  console.log("Iframe Url is: ",response?.data.iframe_url)
                  
    }

    useEffect(
        ()=>{
        fetchUserInfo();
        getIframeLink();
        // console.log(JSON.stringify({ 'etkinlikUcreti':'100',orderId}))
    },[]
    );

    return(
        <div className="homebody">
            <div id="payment_window" className="payment_modal">';
            {/* <div><button class="close" id="modalClose" onclick="closeModal()" class="close" style="float: right;">&times; </button></div>'; */}
            <iframe id="payment-iframe" title="payment iframe" width="100%" height="100%" src={iframeSrc} sandbox="allow-same-origin allow-top-navigation allow-forms allow-scripts" frameborder="2"></iframe> </div>';
 
        </div>
    );
}

export default MakePayment;