import MyFooter from "../components/footer";
import Cart from "../components/satin-al-cart";
import "../App.css";




const SatinAlPage = () =>{
    
    return(

        <div >
            {/* <h5 style={{color:"white"}}>Etkinlik Ödeme Sayfası</h5> */}

            <div >
                <Cart/>
            </div>
        </div>
    );

}

export default SatinAlPage;