import { Button, Card, FloatingLabel, Form, FormGroup, FormLabel, InputGroup } from "react-bootstrap";
import EtkinlikBilgiTable from "./etkinlik-bilgi-table";
import KatilimciListItem from "./katilimci-list-item";
import { useEffect, useState } from "react";
import { CREATE_COMMENT, GET_EVENT_CONTRACT_FILE, GET_EVENT_RELATED_COMMENTS, createBroadCastUrl,
     generalGetMethos, generalPostMethod, getLeaderBoard, getSingleEvent,
      getUserPayments } from "./requests";
import { useLocation, useNavigate } from "react-router-dom";
import { countdown, createDateObj, formatToNormal, formatToTL, getUserTotalPayment, userInAcceptedList } from "../helpers";
import { format } from "date-fns";
import FenomenKatilimciListItem from "./fen-katilimci-list-item";



const ArtirmaliSatis = (props)=>{
    const navigateThere = useNavigate();

    const location = useLocation();
    const eventInfo = location.state || {};

    var user_id = '';
    var user_type ='';
    var first_name ='';
    var last_name ='';

    if (JSON.parse(localStorage.getItem('user_id'))) {  
        user_id = JSON.parse(localStorage.getItem('user_id'))
        user_type = JSON.parse(localStorage.getItem('user_type'))
        first_name = JSON.parse(localStorage.getItem('first_name'))
        last_name = JSON.parse(localStorage.getItem('last_name') )
        
       }

    // const [katilimciSayisi, setKatilimciSayisi] = useState(0);
    const [leaderBoard, setleaderBoard] = useState([]);
    const [errorMessage, seterrorMessage] = useState('');
    const [myTotalAmounPaid, setmyTotalAmounPaid] = useState(0.0);
    const [minPaymentRequired, setminPaymentRequired] = useState(null);
    const [minArtirmaTutari, setminArtirmaTutari] = useState(0.0);
    const [myPayments, setmyPayments] = useState([]);
    const [comments, setcomments] = useState([])


  //Pul the following data from either local storage or navigate there 
  //mechanism thrown from the home page when satin al clicked
  const [etkinlikAdi, setEtkinlikAdi] = useState('');
//   const [etkinlikUcreti, setEtkinlikUcreti] = useState('');
//   const [etkinlikTarihi, setEtkinlikTarihi] = useState('');
//   const [etkinlikMekan, setEtkinlikMekan] = useState('');
  const [etkinlikInfo, setetkinlikInfo] = useState([]);
  const [odemeTutari, setodemeTutari] = useState(); //amount to pay
  const [maxParticipants, setmaxParticipants] = useState(0);
  const [countdowndata, setcountdowndata] = useState({})
  const [comment, setcomment] = useState('');

  const event_id = eventInfo.event_id ||0;  
  // console.log(finalList)  
  var user_id = '';
  if (JSON.parse(localStorage.getItem('user_id'))) {
      user_id = JSON.parse(localStorage.getItem('user_id'));
  }  
  const listHeader = <div className="row gap-0 text-white  w-100">
                          <div className="col-1 ">Sıra</div>
                          <div className="col-9">İsim</div>
                          <div className="col-2">Tutar</div>
                          {/* <div className="col-1"></div> */}  
                       </div>
//get users orders/payments for the current event/etkinlik
function getPayments() {
    const results = getUserPayments({"event_id":event_id, "user_id":user_id}).then(
        (response)=>{
            if (!response.status) {                    
                
                // seterrorMessage('sdfsjkdfksjf')
            }
            else if (response.data.status==='success') {
                const data = response.data.data;
                //set list of payment state
                if (data.payments.length>0) {
                    setmyPayments(data.payments)
                    if (data.total_payment>0) {
                        setmyTotalAmounPaid(data.total_payment)
                    }
                }

                //set total payment state

            }
            else if (response.data.status==='failed') {
                // setminPaymentRequired(minArtirmaTutari)
            }
            else{                    
                
            }
        }
    );
}


//calculate minimum payment amount needed to enter payers list
function calcMinPayment(leaderBoard_,max_allowed_participants,
                        minIncreamentAmnt,biddingEntryAmnt) {
    /**
     * main purporse of this piece of code is to calculate
     * the minimum amount current user has to pay to see 
     * themselves in the accepted biding section of paid 
     * users list. th efunction follows the following rules:
     * If this is first time user is making payment they must 
     * pay the beginner bidding money plus any extra amount
     * they need to pay in order to enter the list.
     * also bidding can only be increamented with a specific
     * minimum, this applies to both now paying bigginer amount
     * as well as those who just wanted to increase their bid.
     * etc. more details later
     */
    

    //first if leaderboard is empty meaning no one including
    //current user has made any payment so min payment required 
    //is the bidding entry amount
    if (leaderBoard_.length<1) {
        setminPaymentRequired(biddingEntryAmnt)
        return 0;
    }

    let bottomOfLeastAcceptedBiddersIndex = null;
    let leastAceptedBid = null;
    let user_total_payment = getUserTotalPayment(leaderBoard_,user_id);
    let userBidInacceptedList = userInAcceptedList(leaderBoard_,user_id);


     //determine the index of the last person/payment in bid range
    //not those outside the accepted bid range
    if (leaderBoard_.length>=max_allowed_participants) {
        bottomOfLeastAcceptedBiddersIndex = max_allowed_participants-1
        leastAceptedBid = leaderBoard_[bottomOfLeastAcceptedBiddersIndex].total_amount 
    } else {
        bottomOfLeastAcceptedBiddersIndex = leaderBoard_.length-1
        leastAceptedBid = leaderBoard_[bottomOfLeastAcceptedBiddersIndex].total_amount        
    }   

    //if user hasnt payed anything yet and max aalowed participants
    // isnt filled yet then user pays just the entry amount
    if (max_allowed_participants>leaderBoard_.length && user_total_payment===0) {
        setminPaymentRequired(biddingEntryAmnt);
        return 0;

    } //if accepted list is full and user isnt in it whatsover,
    // he'll pay more than least payment in the accepted list
    else if(max_allowed_participants<=leaderBoard_.length && !userBidInacceptedList){ //parseFloat(user_total_payment)<parseFloat(leastAceptedBid)){ 
        
        let diffInPayment = Math.abs(user_total_payment-leastAceptedBid)
        // console.log("diff",diffInPayment);
        setminPaymentRequired(diffInPayment+minIncreamentAmnt)
        return 0;
    }else{setminPaymentRequired(0)}
    // else {setminPaymentRequired(0)}
    // var bottomOfLeaderBoard = leaderBoard_[bottomOfLeastAcceptedBiddersIndex]
    // var bottomOfLeaderBoardtAmount = bottomOfLeaderBoard.total_amount;
    // if (myTotalAmounPaid <bottomOfLeaderBoardtAmount) {
        
    //     let minAmountToPay = bottomOfLeaderBoardtAmount-myTotalAmounPaid;
    //     setminPaymentRequired(minAmountToPay>minArtirmaTutari?minAmountToPay:minArtirmaTutari)
    // } else if (myTotalAmounPaid == bottomOfLeaderBoardtAmount &&user_id !==bottomOfLeaderBoard.user_id){
    //     //FIRST CHECK IF USER IS NOT IN THE LIST BEFORE ALLOW FOLLOWIN CODE
    //     setminPaymentRequired(myTotalAmounPaid+minArtirmaTutari)
    // }else{
    //     setminPaymentRequired(0)
    // }
}
    //define get leader board handler
    function fetchAttendees(){
        const results = getLeaderBoard({"event_id":event_id}).then(
            (response)=>{
                if (!response.status) {                    
                    
                    seterrorMessage('Katılımcı listeyi güncelenemedi, tekrardan deneniyor')
                }
                else if (response.data.status==='success')
                 {
                    if (response.data.leader_board.length>0) {
                        
                        seterrorMessage('')
                        // console.log(response.data);
                        setleaderBoard(response.data.leader_board);
                        // getPayments();
                        if (etkinlikInfo.length>0) {
                            calcMinPayment(response.data.leader_board,etkinlikInfo[0].katilim_sayisi,
                                etkinlikInfo[0].minimum_art_tutari,etkinlikInfo[0].baslangic_fiyati);
                        }
                    }else{//if leader board is empty everyone pays minartirmali tutari
                        // setminPaymentRequired(minArtirmaTutari)
                    }
                }
                else if (response.data.status==='failed') {
                    seterrorMessage('Katılımcı listeyi güncelenemedi, tekrardan denenyor')
                }
                else{                    
                    
                    seterrorMessage('Katılımcı listeyi güncelenemedi, tekrardan denenyor')
                   
                }
            }
        );
    }

    // get a single etkinlik info
    function getEtkinlikInfo (){
        const results = getSingleEvent({"event_id":event_id}).then(
            (response)=>{
                if (!response.status) {                    
                    
                    // seterrorMessage('sdfsjkdfksjf')
                }
                else if (response.data.status==='success' && response.data.data.length>0) {
                    const data = response.data.data;
                    setetkinlikInfo(data)
                    // setEtkinlikAdi(data[0].etkinlik_adi);
                    // setEtkinlikTarihi(data[0].etkinlik_tarihi)
                    setminArtirmaTutari(data[0].minimum_art_tutari)
                    setmaxParticipants(data[0].katilim_sayisi)
                    // setminPaymentRequired(data[0].baslangic_fiyati)

                    // if (leaderBoard.length>0) {
                        calcMinPayment(leaderBoard,data[0].katilim_sayisi,
                                    data[0].minimum_art_tutari,data[0].baslangic_fiyati
                                    );
                    // }
                }
                else if (response.data.status==='failed') {
                    //handle error
                    alert("Sistemde bir hata olmuştur. tekrardan giriş yapmaya çalışın.")
                }
                else{                    
                    
                }
            }
        );
    }


    //handle payment submit
    function handleSubmit(e) {
        e.preventDefault()
        //PUT ONLY ETKINLIK ID INTO A LOCAL STORAGE AND MODIFY THE FORWARDED PAGE 
        //(artirmali-satinal-sayfasi) TO MAKE REQUEST FOR IT'S OWN DATA USING EVENT AND USER
        //ID'S FROM LOCAL STORAGE
        //THIS WILL BE HELPFULL WHEN PAYMENT PAGE RETURNS

        //check to enforce minimum payment amount
        let amoutnToPay = formatToNormal(odemeTutari);
        
        if (!userInAcceptedList(leaderBoard,maxParticipants,user_id)&& amoutnToPay<minPaymentRequired) {
            alert("Ödemeniz minimum gereken ödeme tutarından az kabul edilmemektedir!")
            
        } else if(userInAcceptedList(leaderBoard,maxParticipants,user_id)&& amoutnToPay<minArtirmaTutari){
            alert("Ödemeniz minimum artirmali tutarından  az kabul edilmemektedir!")
        }else{
            const data = {user_id: user_id, event_info:etkinlikInfo, payment_amount:odemeTutari}
            navigateThere('/artirmali-satinal-sayfasi',{state:data}) //pass event id and amout to pay
        }
    }

    //for creating comment
    function handleCreateComment(event) 
        {
            event.preventDefault();
            generalPostMethod(CREATE_COMMENT,{'event_id':event_id, 'text':comment}).then(
                (response) => { 
                    if (response.status===200) {
                        setcomment("")
                        //Handle making an alert that comment sent with dialog popup
                    } else {
                       //Handle making an alert that comment sent with dialog popup

                    }
                 }
            )
        }

    //fetch event related comments
    function fetchComments() {
        generalGetMethos(GET_EVENT_RELATED_COMMENTS+'?event_id='+event_id).then(
            (response) => { 
                if (!response.status) {
                    //dialog to say comment not fetched
                } else if(response.status===200){
                    setcomments(response.data.comments)
                }
             }
        ).catch()
    }

    useEffect(() => {
        getEtkinlikInfo();
        //get payment leader board on inititial load of the page
        fetchAttendees();

        fetchComments();
    }, [myTotalAmounPaid]);

    //it is necessary to have a separate useeffect for calculating user payments
    //since the above useeffect has myTotalamountPaid in it's dependency list
    //putting getPayments() function in the above useeffect will cause infinite loop
    useEffect(() => {
        getPayments()    
    
    }, []);

    //useeffect for running calMinPayment function
    useEffect(() => {
        if (etkinlikInfo.length>0 && leaderBoard.length>0) {
            if (etkinlikInfo.length>0) {
                calcMinPayment(leaderBoard,etkinlikInfo[0].katilim_sayisi,
                    etkinlikInfo[0].minimum_art_tutari,etkinlikInfo[0].baslangic_fiyati);
            }
        }
    
    }, [etkinlikInfo,leaderBoard]);

    //useeffect for streaming changes in payments
    useEffect(() => {
        //establish connection to message broadcast
        let channelUri = createBroadCastUrl(event_id);
        // console.log(channelUri);

        let eventSource = new EventSource(channelUri);

        // console.log("about to retrieve message");
        eventSource.onmessage = function(event) {
        let message = JSON.parse(event.data);
        let parsed_data = JSON.parse(message.data)
        let max_allowed_participants = parsed_data['max_allowed_participants']       
        let leader_board = parsed_data['leader_board']
        if (leader_board.length>0) {
            
            setleaderBoard(leader_board);
            if (etkinlikInfo.length>0) {
                calcMinPayment(leader_board,etkinlikInfo[0].katilim_sayisi,
                    etkinlikInfo[0].minimum_art_tutari,etkinlikInfo[0].baslangic_fiyati);   
            }
        }else{
            // console.log(minArtirmaTutari);
            // setminPaymentRequired(minArtirmaTutari)
        }
     };
    }, [])

//useeffect for streaming changes in comments
useEffect(() => {
    //establish connection to message broadcast
    let channelUri = createBroadCastUrl(event_id+'_comments');
    // console.log(channelUri);

    let eventSource = new EventSource(channelUri);

    // console.log("about to retrieve message");
    eventSource.onmessage = function(event) {
    let comments_payload = JSON.parse(event.data);
    let parsed_data = JSON.parse(comments_payload.data)
    let comments = parsed_data['comments']
    if (comments.length>0) {
        setcomments(comments);
    }else{
        // console.log(minArtirmaTutari);
        // setminPaymentRequired(minArtirmaTutari)
    }
 };
}, [])

//useefect for the time chronometer/countdown
useEffect(() => 
    {
       if (etkinlikInfo.length>0) {
           
           const target_timestamp = createDateObj(etkinlikInfo[0].satis_bitis_tarihi,
               etkinlikInfo[0].s_bisaat)
   
           const interval = setInterval(() => {
           const cntDnData = countdown(target_timestamp) //starter_milSeconds)
   
           setcountdowndata(cntDnData)
           }, 600); // 60000 milliseconds = 1 minute
       
           // Clear the interval when the component unmounts
           return () => clearInterval(interval);
       }
    }, [etkinlikInfo]);
    

    return(

    <div className="row  flex-row justify-content-center">
        <div className=" col-sm-6 mx-auto justify-content-center">
        <div className="row  flex-row justify-content-center">
        <div className="row g-1 mb-4 justify-content-center">
            <div className="col-8">
                <EtkinlikBilgiTable event_id ={event_id}/>
            </div>
            <div className="col-4">
            <div className="h-[5.7rem] rounded-md bg-white p-1">
                    <h5 className="text-sm m-0 mb-2" >Kalan Süre</h5>
                    <p className='text-[0.6rem] my-0 bg-red-200 rounded-md'>
                        <span className="ml-1">{countdowndata.days} Gün</span>
                        <span className="ml-1">{countdowndata.hours} Saat</span>
                        <span className="ml-1">{countdowndata.minutes} Dakıka</span>
                        <span className="ml-1">{countdowndata.seconds} Saniye</span>
                    </p>
                </div>        
            </div>
        {/* <div className="row py-3 bg-danger-subtle  "> */}
            
        
        {/* </div>     */ }

        </div>
        <h3 className="text-white text-start ps-0">Katılımcı Listesi</h3>
        {/* {listHeader} */}
        <div className="row bg-white overflow-y-auto
          flex h-25 justify-content-center border
           rounded-3 w-100 mb-3" style={{maxHeight:'15rem'}}>
            {user_type==9?
                <FenomenKatilimciListItem maxParticipants={maxParticipants} leaderBoard={leaderBoard}/>
                :
                <KatilimciListItem maxParticipants={maxParticipants} leaderBoard={leaderBoard}/>
            }
            {/* {finalList} */}
        </div>

        {!isNaN(minPaymentRequired)&&
        <div className="row w-100 px-0">
            <div className="col-8 rounded-start-3 " style={{backgroundColor:'lightblue'}}>
                <Form onSubmit={handleSubmit}>
                <FormLabel className="text-white" htmlFor="artodemetutari" >ÖDEME YAP</FormLabel>
                <InputGroup size="sm">
                    <InputGroup.Text>Tutar Giriniz</InputGroup.Text>
                <Form.Control id="artodemetutari" required
                value={odemeTutari}
                onBlur={(e) => { 
                    if (e.target.value) {//this is to prevent seeing NAN when nothing is entered
                        setodemeTutari(formatToTL(e.target.value))
                    }
                 }}

                onChange={(e) => {
                    setodemeTutari(e.target.value);
                }}
                />
                <Button style={{backgroundColor:"blue"}}
                type="submit"
                variant="primary">Öde</Button>
                </InputGroup>
                <Form.Check type="checkbox" className="text-start" >
                <Form.Check.Input type={"checkbox"} className="ps-0" required/>
                    <Form.Check.Label className="text-white">
                        Kullanıcı {etkinlikInfo.length>0?
                         <a href={GET_EVENT_CONTRACT_FILE+etkinlikInfo[0].contract_file_url}
                          target="_blank" rel="noopener noreferrer">
                            Sözleşmesi
                        </a>:"" }
                    </Form.Check.Label>
                </Form.Check>

                {/* <Form.Check type="checkbox" className="text-start" > */}
                {/* <Form.Check.Input type={"checkbox"} className="ps-0" required/>
                    <Form.Check.Label className="text-white">Ödeme Bilgilermi Oku</Form.Check.Label>
                </Form.Check> */}
                </Form>
            </div>
            
           
            <div className="col-4 p-0">
                <div className="h-100 w-100 p-0 m-0 bg-white rounded-end-2">
                    <div className="w-100 pt-2 pb-0" style={{fontSize:'0.7rem'}}>
                        <p className="mb-0  ">
                         Toplam Ödemeniz:
                        </p>
                        <span className="" style={{fontSize:'1rem'}}>
                            {myTotalAmounPaid&&formatToTL(myTotalAmounPaid.toString(),"database")} TL
                        </span>
                    </div>
                    {!userInAcceptedList(leaderBoard,maxParticipants,user_id)&&
                     <p className="w-100 pb-0 mb-0 bg-white text-black" style={{fontSize:'0.7rem'}}>
                        LİSTEYE GİRMEK İÇİN MİN. GEREKEN ÖDEMENİZ: 
                        <span className="">
                            {minPaymentRequired&&formatToTL(minPaymentRequired.toString(),"database")} TL
                        </span>                        
                    </p>
                    }
                </div>
            </div>
        </div>
        }

        <div className="row mt-4 px-0 ">
            <div className="col-8 bg-white rounded-start-3 ">

            <h3>Yaptığım Ödemeler</h3>
                <ul className="text-black">
                <div style={{fontSize:'10px', display:"flex",justifyContent:"space-between"}}
                         className="text-sm-start bg-gradient  ">
                            <p>Tutar</p>
                            <p style={{paddingRight:"45px"}} className="">Gün/Saat</p>
                        </div>
                <div className=" overflow-y-auto" style={{height:"9rem"}}>

                    {myPayments&&myPayments.map((record) => (

                        <div key={record.updated_at}
                         style={{fontSize:'10px',height:"20px", display:"flex",justifyContent:"space-between",paddingBottom:"0px", paddingInline:"3px"}}
                         className="text-sm-start bg-light rounded-2 border-1 border ">
                            <p>{formatToTL(record.payment_amount.toString(),"database")} TL</p>
                            <p >{format(record.updated_at,"dd-MM-yyyy/hh:mm")}</p>
                        </div>
                    )
                    )}
                </div>
                    
                </ul>
            </div>
             <div className="col-4 pe-0 ">
             <div className="h-100 w-100 p-0 m-0 bg-white rounded-end-2 " body>
                    <p className="w-100 pt-2 text-start h-100 text-black text-bg-warning rounded-end-2 ps-1 pb-0 " 
                    style={{fontSize:'0.8rem'}}>
                        <strong>Not:</strong> Belirlenen satın alma
                         süresi içerisinde listeye giremediğiniz 
                         takdirde, etkinlik satın alma süresi 
                         bitince ödemeniz iade edilecektir.
                    </p>
                    
                </div>
             </div>

        </div>

        <div className="col-8 mt-4 g-0">
                <Form onSubmit={(event) => { handleCreateComment(event) }}>
                    <FormGroup className="text-end">
                        <FloatingLabel
                            controlId="floatingTextarea"
                            label="Yorum"
                            className=""
                        >
                            <Form.Control as="textarea"
                            value={comment}
                            onChange={(event) => { setcomment(event.target.value) }}
                             placeholder="Leave a comment here" />
                        </FloatingLabel>

                        <Button type="submit" size="sm" className="mt-0 bg-danger-subtle ">Gönder</Button>
                    </FormGroup>
                </Form>
            </div>
            {/* user comments */}

            <div className="overflow-y-auto h-[25rem]">
            {comments&&
                comments.map((comment) => (
                    <div key={comment.id}>
                        <p className="w-full mb-0 text-xs text-white text-end">
                            {comment.nick+"  "+" | "+format(Date(comment.created_at),"dd-MM-yyyy HH:mm")}
                        </p>
                        <p className='feno-yorumlarim text-sm' >
                            {comment.text}
                        </p>
                    </div>

            ))}
            </div>
            </div>
            </div>
    </div>
    );

}

export default ArtirmaliSatis;