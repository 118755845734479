// ErrorBoundary.js
import React from "react";
import { Link } from "react-router-dom";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can log the error to an error reporting service if needed
    console.error("ErrorBoundary caught an error", error, info);
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI when an error occurs
      return (
        <div className="w-screen h-screen flex justify-center items-center">

            <div
            className="bg-red-100 h-[20rem] p-6 rounded-md w-fit text-red-100"
            style={{ textAlign: "center", marginTop: "50px" }}>
            <h1 className="bg-red-300 rounded-sm">Beklenmeyen bir hata oluşmuş!</h1>
            {/* <p>Lütfen daha sonra tekrar deneyin.</p> */}
            <Link to="/">
                <button 
                onClick={() => this.setState({ hasError: false })}

                style={{ padding: "10px 20px", fontSize: "16px" }}>
                Anasayfaya Dön
                </button>
            </Link>
            </div>
        </div>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
