import { useState } from "react";
import HesapDogrulama from "../components/hesap-dogrulama-c";
import { sendVerificationCode } from "../components/requests";


const DogrulamaPage = ()=>{

        //get current user id and type
    var user_id = '';
    var user_type ='';
    if (JSON.parse(localStorage.getItem('user_id'))) {  
        user_id = JSON.parse(localStorage.getItem('user_id'))
        user_type = JSON.parse(localStorage.getItem('user_type'))
    }


    const [started, setStarted] = useState(false);
    const [errorMessage, seterrorMessage] = useState('');

    //define error component
    const errorComp = <p className="bg-danger text-black ">{errorMessage}</p>

    const handleStart = ()=>{
        // const user_id = localStorage.getItem('user_id');
        seterrorMessage('')
        const results = sendVerificationCode({'user_id':user_id}).then(
            (response)=>{
                if (!response.status) {                    
                    
                    seterrorMessage('Üzgünüz, doğrulama kodunuzu gönderemedik.')
                    setStarted(false);
                }
                else if (response.data.status==='success') {
                    setStarted(true)
                }
                else if (response.data.status==='failed') {
                    setStarted(false)
                    seterrorMessage(response.data.message);
                }
                else{                     
                    seterrorMessage('Üzgünüz, doğrulama kodu gonderemedik.')
                    setStarted(false);
                }
            }
        );
    }

    //define pre verification note component
    const priDiv = <div className="w-80 h-50 text-start px-2 verification-page  mx-auto mt-3 text-white-50 " >   
                {errorMessage&&errorComp}
                Hesap doğrulamanızı başlatmak için:<br/>
                -İlk önce, İnstagram hesabınızdan "buradagocom" instagram hesabımıza Doğrudan Mesaj (DM)  ile "kod" yazarak gönderiniz.
                <br/>
                -"kod" mesajınızı gönderdikten sonra aşağıdaki "Doğrulamaya Başla" butonuna basınız.
                <br/>
                -İşlemleri sırasıyla yaptığınızda,bir sonraki sayfada İnstagram hesabınıza gelen Doğrulama Kodunu girerek işleminizi bitirebilirsiniz.

                    <div className="flex justify-center">
                        <button 
                        onClick={handleStart}
                        className="rounded mt-3  bg-blue-500 text-white
                        px-3 mb-1 "> Doğrulamaya Başla</button>
                    </div>
                </div>

    return(
        <div className="  justify-content-center ">
         <h1 className="text-white-50 mt-5">Hesap Doğrulanması</h1>       
            {!started&&priDiv}
            {started&&<HesapDogrulama/>}
        </div>

    );
}

export default DogrulamaPage;